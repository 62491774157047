<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-btn
          small
          color="primary"
          class="ml-4"
          @click="$router.push('/pages/produtos')"
        >
          Voltar
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="8"
      >
        <h1>Produto</h1>
        <v-form
          lazy-validation
        >
          <v-text-field
            v-model="produto.nome"
            label="Nome"
            name="nome"
            type="text"
            :rules="rules"
          />

          <v-text-field
            v-if="!agrupado"
            v-model="produto.ncm"
            label="NCM"
            name="ncm"
            type="text"
            :rules="rules"
          />

          <v-text-field
            v-model="produto.grupo"
            label="Grupo"
            name="grupo"
            type="text"
            :rules="rules"
          />

          <v-text-field
            v-model="produto.catalogoProduto"
            label="Catálogo Produto"
            name="catalogoProduto"
            type="text"
          />

          <v-text-field
            v-model="produto.referencia"
            label="Referência"
            name="referencia"
            type="text"
            :rules="rules"
            @blur="setReferenciaConfig()"
          />

          <tiptap-vuetify
            v-model="produto.descricao"
            label="Descrição"
            name="descricao"
            :extensions="extensions"
          />

          <label
            for="imgprincipal"
            class="grey--text lighten-2"
          >
            Imagem principal atual:
          </label>

          <v-img
            v-if="produto.urlImagem"
            id="imgprincipal"
            :src="produto.urlImagem"
            width="250"
          />

          <v-file-input
            v-model="foto"
            accept="image/*"
            chips
            label="Imagem principal"
          />

          <v-autocomplete
            v-model="selectedCategorias"
            label="Categoria"
            :items="categorias"
            item-text="nome"
            item-value="categoriaId"
            :rules="rules"
            chips
            multiple
          />
          <v-switch
            v-model="produto.ativo"
            :label="produto.ativo ? 'Produto está ATIVO' : 'Produto está INATIVO'"
          />
          <v-switch
            v-model="produto.oportunidade"
            label="Oportunidade?"
            @change="validarOportunidade"
          />
          <v-switch
            v-model="exclusivoClub"
            label="Exlusivo clube Styllus?"
          />
          <v-text-field
            v-if="exclusivoClub"
            v-model="produto.score"
            label="Pontuação mínima do Clube"
            name="score"
            type="number"
          />
          <v-btn
            block
            color="primary"
            class="mt-4"
            @click="submit"
          >
            Salvar
          </v-btn>
        </v-form>
      </v-col>
      <template v-if="produto.id && !agrupado">
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-btn
            color="indigo"
            fab
            dark
            :to="`/pages/produto/${produto.id}/configuracao`"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-tabs
            fixed-tabs
            slider-color="primary"
          >
            <v-tab ripple>
              Resumo
            </v-tab>
            <v-tab ripple>
              Detalhado
            </v-tab>
            <v-tab-item>
              <v-card flat>
                <v-simple-table>
                  <produto-resumo :configuracoes="produto.configuracoes" />
                </v-simple-table>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card
                v-for="item in produto.configuracoes"
                :key="item.id"
                class="ma-1 py-1"
              >
                <configuracao-produto-form :id="item.id" />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-alert
            v-if="!produto.configuracoes.length"
            border="right"
            colored-border
            type="error"
            elevation="2"
          >
            Esse produto ainda não possui grade de cor e tamanho.
          </v-alert>
        </v-col>
      </template>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="90%"
      width="500px"
    >
      <v-card>
        <v-card-title>
          <strong>Atenção!</strong>
        </v-card-title>

        <v-card-text>
          Quando marcamos o produto como oportunidade ele não poderá ter mais desconto aplicado. Deseja prosseguir?
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            @click="submit"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

  export default {
    name: 'ProdutoForm',
    components: {
      ProdutoResumo: () => import('./ProdutoResumo'),
      ConfiguracaoProdutoForm: () => import('./ConfiguracaoProdutoForm'),
      TiptapVuetify,
    },
    data () {
      return {
        dialog: false,
        missingPromocional: false,
        categorias: [],
        cores: [],
        tamanhos: [],
        arquivos: [],
        foto: null,
        produto: {
          ativo: true,
          configuracoes: [],
          agrupamento: [],
        },
        configuracao: {},
        exclusivoClub: false,
        rules: [
          v => !!v || 'Este campo é obrigatório',
        ],
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        selectedCategorias: [],
        extensions: [
          History,
          Blockquote,
          Link,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [Heading, {
            options: {
              levels: [1, 2, 3],
            },
          }],
          Bold,
          Code,
          HorizontalRule,
          Paragraph,
          HardBreak,
        ],
      }
    },
    computed: {
      agrupado () {
        return this.produto.agrupamento && this.produto.agrupamento.length > 0
      },
    },
    mounted () {
      if (this.$route.params.id) {
        this.load()
      }
      this.$http.get('/produto/categoria')
        .then(resp => {
          this.categorias = resp.data.rows
        })
      this.$http.get('/produto/cor')
        .then(resp => {
          this.cores = resp.data.rows
        })
      this.$http.get('/produto/tamanho')
        .then(resp => {
          this.tamanhos = resp.data.rows
        })
    },
    methods: {
      validarOportunidade () {
        this.produto.configuracoes = this.produto.configuracoes.map(x => {
          x.errored = this.produto.oportunidade && !x.valorPromocional
          return x
        })
        this.missingPromocional =
          (this.produto.configuracoes.filter(x => x.errored).length > 0)
      },
      load () {
        this.$http.get(`/produtos/${this.$route.params.id || this.produto.id}`)
          .then(resp => {
            this.produto = resp.data
            this.selectedCategorias = this.produto.categorias.map(x => x.categoriaId)
            this.exclusivoClub = this.produto.score > 0
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      destroy (configuracao) {
        if (configuracao.produtoConfiguracaoId) {
          this.$http.delete(`/configuracaoproduto/${configuracao.produtoConfiguracaoId}`)
            .then(resp => {
              this.removeFromArray(configuracao)
              this.$toast.success('Operação realizada com sucesso')
            })
            .catch(() => {
              this.$toast.error('Falha ao realizar operação')
            })
        } else {
          this.removeFromArray(configuracao)
        }
      },
      removeFromArray (configuracao) {
        const idx = this.produto.configuracoes.indexOf(configuracao)
        if (idx >= 0) {
          this.produto.configuracoes.splice(idx, 1)
        }
      },
      submit () {
        this.produto.produtosCategoria = this.selectedCategorias
        if (!this.exclusivoClub) {
          delete this.produto.score
        }
        if (this.produto.oportunidade) {
          if (this.missingPromocional) {
            this.$toast.error('Para definir uma oportunidade, todas as configurações devem possuir um valor promocional.')
            return
          }
          if (!this.dialog) {
            this.dialog = true
            return
          }
        }
        if (this.$route.params.id) {
          this.put()
        } else {
          this.post()
        }
      },
      setReferenciaConfig () {
        this.configuracao.referencia = this.produto.referencia + '/'
      },
      addConfig () {
        this.configuracao.valor = parseFloat(this.configuracao.valor)
        this.configuracao.tipoDeProdutoId = 2
        this.produto.configuracoes.push({ ...this.configuracao })
        this.configuracao.referencia = this.produto.referencia + '/'
      },
      post () {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        const formData = new FormData()
        this.produto.produtosCategoria = this.produto.produtosCategoria.map(x => {
          return {
            CategoriaId: x,
          }
        })

        if (this.foto) {
          formData.append('foto', this.foto)
        }

        formData.append('produto', JSON.stringify(this.produto))

        this.$http
          .post('/produtos', formData, config)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push(`/pages/produtos/${resp.data.id}`)
            this.produto.id = resp.data.id
            this.produto.produtoId = resp.data.id
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      criarNoBling (item) {
        this.$http.post('/bling/produto/', item)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
          })
      },
      put () {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        const formData = new FormData()
        this.produto.produtosCategoria = this.produto.produtosCategoria.map(x => {
          return {
            CategoriaId: x,
          }
        })
        this.produto.produtoId = this.produto.id
        if (this.foto) {
          formData.append('foto', this.foto)
        }
        formData.append('produto', JSON.stringify(this.produto))
        this.$http
          .put('/produtos', formData, config)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      valorPromocional (item) {
        if (!item.valorPromocional) {
          return 'N/A'
        }
        return parseFloat(item.valorPromocional).toFixed(2)
      },
    },
  }
</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;
  }
  .configs {
    padding: 16px;
    margin-top: 16px;
  }
  .btn-add {
    margin-bottom: 16px;
  }
  .text-danger {
    color: red;
    font-weight: bold;
  }
</style>
